import React from 'react';
import AuthRouter from 'routes/AuthRouter';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        backgroundColor: '#fff',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        minWidth: '700px',
        padding: 0,
    },
}));

const Auth = ({ url, ...props }) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.root}>
                <main className={classes.content}>
                    <Container maxWidth="xl" className={classes.container}>
                        <AuthRouter url={url} />
                    </Container>
                </main>
            </div>
        </>
    );
};

export default Auth;
