import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import Routes from 'routes';
import customTheme from 'theme';
import './App.css';
import { DrawerProvider } from 'context/DrawerContext';
import { AuthProvider } from 'context/AuthContext';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { useNetwork } from 'react-use';
import { useNotifications } from 'context/NotificationsContext';
import { isEmpty } from 'lodash';

const App = () => {
    const muiTheme = customTheme();
    const network = useNetwork();
    const { notify } = useNotifications();
    const [prevNetState, setPrevNetState] = useState('online');

    useEffect(() => {
        console.log({ isEmpty: isEmpty(network), online: network.online });
        if (!isEmpty(network) && !network.online) {
            setPrevNetState('offline');
            notify({ type: 'error', message: 'Zəhmət olmasa internet bağlantınızı yoxlayın', duration: 200000 });
        }
        if (!isEmpty(network) && network.online && prevNetState === 'offline') {
            setPrevNetState('online');
            notify({ type: 'success', message: 'İnternet bağlantısı bərpa olundu' });
        }
    }, [network]);

    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <ThemeProvider theme={muiTheme}>
                <AuthProvider>
                    <DrawerProvider>
                        <CssBaseline />
                        <Routes />
                    </DrawerProvider>
                </AuthProvider>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
};

export default App;
