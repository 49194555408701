import React from 'react';
import { Grid, Button } from '@material-ui/core';

//components
import { useStyles } from './deleteModal.style';
import { RModal } from './rModal';

interface DeleteModalProps {
    modalVisible?: any;
    handleCloseDeleteModal?: any;
    deleteFunction?: any;
    deleteItemIds?: number[] | number;
    setData?: any;
    setDeleting?: any;
    notify?: any;
    setShowCalendar?: any;
}
export const DeleteModal: React.FC<DeleteModalProps> = ({
    modalVisible,
    handleCloseDeleteModal,
    deleteFunction,
    deleteItemIds,
    setData,
    setDeleting,
    notify,
    setShowCalendar,
}) => {
    const classes = useStyles();

    return (
        <RModal open={modalVisible} handleClose={handleCloseDeleteModal} className={classes.modal}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <p className={classes.deleteModalText}>Silmək istədiyinizə əminsiniz?</p>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.commonModalButtons}>
                        <Button
                            onClick={() => {
                                deleteFunction(
                                    deleteItemIds,
                                    setDeleting && setDeleting,
                                    setData,
                                    handleCloseDeleteModal,
                                    notify && notify,
                                    setShowCalendar && setShowCalendar,
                                );
                            }}
                            variant="contained"
                            color="secondary"
                        >
                            BƏLİ
                        </Button>
                        <Button variant="contained" onClick={handleCloseDeleteModal}>
                            XEYR
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </RModal>
    );
};
