import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({



    commonModalAddFile: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        textTransform: 'initial',
        transition: 'all 0.2s',
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: '#33B5E5',
        },
        '& svg': {
            fontSize: '24px',
            color: '#33B5E5',
        },
    },
    addedFiles: {
        width: '100%',
        padding: '0px 10px',
        '& p': {
            padding: '0px 20px',
            fontSize: '14px',
            color: '#E5007C',
        },
    },

    selectedFiles: {
        width: '100%',

        '& li': {
            width: '100%',
            paddingBottom: 10,
        },

        '& svg': {
            color: 'red',
            fontSize: 20,
        },
    },
    addFilesTitle: {
        padding: '0px!important',
        fontSize: '16px!important',
        color: 'gray!important',
    },


}));
