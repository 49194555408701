import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 210;
const drawerWidthMD = 210;

const headerHeight = 177;
const headerHeightMD = 120;

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            boxSizing: 'border-box',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            [theme.breakpoints.down(1500)]: {
                width: drawerWidthMD,
            },
            '& .MuiDrawer-paperAnchorDockedLeft': {
                border: 'none',
            },
        },
        drawerPaper: {
            width: drawerWidth,
            [theme.breakpoints.down(1500)]: {
                width: drawerWidthMD,
            },
        },
        drawerOpen: {
            width: drawerWidth,
            backgroundColor: '#F1F5F8',
            color: '#707070',
            [theme.breakpoints.down(1500)]: {
                width: drawerWidthMD,
            },
        },
        drawerClose: {
            backgroundColor: '#F1F5F8',
            color: '#707070',
            overflowX: 'hidden',
            width: theme.spacing(9) + 1,
        },
        divider: {
            color: '#ffffff',
        },
        imageLogo: {
            width: 141,
            height: 96,

            [theme.breakpoints.down(1500)]: {
                width: 100,
                height: 68,
            },
        },
        imageLogoClosed: {
            display: 'none',
        },
        userInfo: {
            backgroundColor: '#F1F5F8',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 26px 0 41px',
            minHeight: '110px',
            fontWeight: 900,
            marginBottom: '30px',
            [theme.breakpoints.down(1500)]: {
                padding: '0 20px 0 35px',
                marginBottom: '20px',
            },
        },
        userInfoClosed: {
            padding: 0,
            justifyContent: 'center',
        },
        imageUser: {
            width: 55,
            height: 55,
            marginRight: '30px',
            [theme.breakpoints.down(1500)]: {
                width: 44,
                height: 44,
                marginRight: '20px',
            },
        },
        imageUserClosed: {
            width: 35,
            height: 35,
            marginRight: 0,
        },
        typography: {
            justifySelf: 'flex-start',
            fontSize: '19px',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            [theme.breakpoints.down(1500)]: {
                fontSize: '16px',
            },
        },
        smallText: {
            display: 'block',
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#9B9B9B',
            [theme.breakpoints.down(1500)]: {
                fontSize: '13px',
            },
        },
        list: {
            overflowY: 'auto',
            margin: 0,
            padding: 0,
            listStyle: 'none',
            height: '100%',
            '&::-webkit-scrollbar': {
                width: '0px',
                height: '0px',
            },
            '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
                backgroundColor: '#F1F5F8',
            },
            '& .Mui-selected': {
                color: '#2662F0',
                fill: '#2662F0',
                '& button svg path': {
                    fill: '#2662F0',
                },
            },
            '& .MuiIconButton-root': {
                padding: 0,
                color: 'darkslategray',
            },
        },
        listItems: {
            height: '60px',

            '&:hover': {
                color: '#2662F0',
                '& .MuiIconButton-root': {
                    color: '#2662F0',
                },
            },
        },
        listItemsClosed: {
            borderLeft: 0,
            display: 'flex',
            justifyContent: 'center',
            '&:hover': {
                backgroundColor: '#F1F5F8',
            },
        },
        listItemText: {
            fontSize: '13px',
            marginLeft: '30px',
            fontWeight: 500,
            [theme.breakpoints.down(1500)]: {
                fontSize: '16px',
                marginLeft: '25px',
            },
        },
        icon: {
            marginLeft: '10px',
            width: '22px',
            height: 'auto',
            [theme.breakpoints.down(1500)]: {
                marginLeft: '5px',
            },
            '&:focus': {
                backgroundColor: '#F1F5F8',
                color: '#2662F0',
            },
        },
        iconClosed: {
            marginLeft: 0,
        },
        toolbarButton: {
            display: 'flex',

            minHeight: headerHeight,
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: theme.spacing(0, 1),
            [theme.breakpoints.down(1500)]: {
                minHeight: headerHeightMD,
            },
        },
        toolbarButtonClosed: {
            right: 'unset',
        },
        toolbar: {
            display: 'flex',
            minHeight: headerHeight,
            alignItems: 'center',
            padding: theme.spacing(0, 0.5),
            [theme.breakpoints.down(1500)]: {
                minHeight: headerHeightMD,
            },
        },
        active: {},
    };
});
