import { Container, makeStyles } from "@material-ui/core";
import { Sidebar } from "../sidebar/sidebar";
import React, { useState, useEffect } from "react";
import AppRouter from "routes/AppRouter";
import AuthGuard from "../auth/AuthGuard";
import { ACCESS_TOKEN_KEY } from "context/AuthContext";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useRealTimeContext } from "context/RealTimeContext";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#F1F5F8",
    "& .MuiInputBase-root.Mui-disabled": {
      color: "darkslategray", // (default alpha is 0.38)
    },
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    minWidth: "600px",
    padding: 0,
    "& .MuiGrid-spacing-xs-1": {
      width: "calc(100% + 3px)",
    },
  },
}));

const App = ({ url, ...props }) => {
  const classes = useStyles();
  const userToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const signalrEndpoint = `${process.env.REACT_APP_API_URL}/hubs/notification`;
  const [connection, setConnection] = useState(null);
  const {
    getAllNotificationsPageData,
    getNotificationsPanelData,
  } = useRealTimeContext();

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(signalrEndpoint, {
        accessTokenFactory: () => (userToken !== null ? userToken : ""),
      })
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          console.log("Connected!");

          connection.on("Receive", (message) => {
            getAllNotificationsPageData();
            getNotificationsPanelData();
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection]);

  return (
    <AuthGuard>
      <div className={classes.root}>
        <Sidebar />
        <main className={classes.content}>
          <Container maxWidth="xl" className={classes.container}>
            <AppRouter url={url} />
          </Container>
        </main>
      </div>
    </AuthGuard>
  );
};

export default App;
