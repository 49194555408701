/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState } from 'react';

const DrawerContext = createContext({
    open: false,
    openDrawer: () => {},
    closeDrawer: () => {},
});
export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [open, setOpen] = useState(true);

    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);

    return <DrawerContext.Provider value={{ open, openDrawer, closeDrawer }}>{children}</DrawerContext.Provider>;
};
