import React, { Component } from "react";
import App from "./App";
import { NotificationsProvider } from "context/NotificationsContext";
import { RealTimeProvider } from "context/RealTimeContext";

export default class Setup extends Component {
  componentDidCatch(error: any): void {
    console.warn(error);
  }

  render(): React.ReactNode {
    return (
      <NotificationsProvider>
        <RealTimeProvider>
          <App />
        </RealTimeProvider>
      </NotificationsProvider>
    );
  }
}
