//From Materail UI
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useStyles } from './FileSection.style';

interface Props {
    arr: any[];
    setArr: any;
    loading: any;
}

export const FileSection: React.FC<Props> = ({ arr, setArr, loading }) => {
    const classes = useStyles();

    const deleteFile = (selectedIndex) => {
        const result = arr.filter((file, index) => {
            if (index !== selectedIndex) {
                return file;
            }
        });
        setArr(result);
    };

    return (
        <Grid item xs={12} style={{ minHeight: '10px', height: 'auto' }}>
            <div className={classes.addedFiles}>
                {arr.length > 0 ? (
                    <>
                        <p className={classes.addFilesTitle}>Əlavə olunmuş fayllar</p>
                        <ul className={classes.selectedFiles}>
                            {Array.from(arr).map((file, index) => {
                                return (
                                    <li key={index}>
                                        {(file as any).name}
                                        <IconButton style={{ marginLeft: 10 }}>
                                            {!loading && (
                                                <CloseIcon
                                                    onClick={() => {
                                                        deleteFile(index);
                                                    }}
                                                />
                                            )}
                                        </IconButton>
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                ) : (
                    <p style={{ color: 'gray' }}>Əlavə olunmuş fayl yoxdur</p>
                )}
            </div>
        </Grid>
    );
};
