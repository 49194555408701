import { api } from "api/api";
import React, { createContext, useContext, useState, useEffect } from "react";

type NotificationsPageData = {
  id: number;
  title: string;
  content: string;
  isRead: true;
  url: string;
};
type NotificationsPanelData = {
  count: number | undefined;
  notifications: {
    id: number;
    title: string;

    content: string;

    isRead: true;
    url: string;
  }[];
};
type RealTimeContextProps = {
  notificationsPageData: NotificationsPageData[];
  notificationsPanelData: NotificationsPanelData;
  getAllNotificationsPageData: () => void;
  getNotificationsPanelData: () => void;
  updateNotification: (id: number) => void;
};

const RealTimeContext = createContext<RealTimeContextProps>({
  notificationsPageData: [],
  notificationsPanelData: {
    count: 0,
    notifications: [
      {
        id: 0,
        title: "",

        content: "",

        isRead: true,
        url: "",
      },
    ],
  },
  getAllNotificationsPageData: () => {},
  getNotificationsPanelData: () => {},
  updateNotification: () => {},
});

export const useRealTimeContext = () => useContext(RealTimeContext);

export const RealTimeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [notificationsPageData, setNotificationsPageData] = useState<
    NotificationsPageData[]
  >([]);
  const [notificationsPanelData, setNotificationsPanelData] = useState<
    NotificationsPanelData
  >({
    count: 0,
    notifications: [
      {
        id: 0,
        title: "",

        content: "",

        isRead: true,
        url: "",
      },
    ],
  });

  const getAllNotificationsPageData = async (): Promise<void> => {
    try {
      const axios = await api();
      const res = await axios.get("/notification");
      setNotificationsPageData(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getNotificationsPanelData = async (): Promise<void> => {
    try {
      const axios = await api();
      const res = await axios.get("/notification/new");
      setNotificationsPanelData(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const updateNotification = async (id): Promise<void> => {
    try {
      const axios = await api();
      await axios.put(`notification/${id}`);
      setNotificationsPageData((prev) =>
        prev.map((notification) =>
          notification.id === id
            ? { ...notification, isRead: true }
            : notification
        )
      );
      setNotificationsPanelData((prev) => ({
        ...prev,
        count: prev.count ? prev?.count - 1 : 0,
        notifications:
          prev?.notifications.map((notification) =>
            notification.id === id
              ? { ...notification, isRead: true }
              : notification
          ) || [],
      }));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllNotificationsPageData();
    getNotificationsPanelData();
  }, []);
  return (
    <RealTimeContext.Provider
      value={{
        notificationsPageData,
        notificationsPanelData,
        getNotificationsPanelData,
        getAllNotificationsPageData,
        updateNotification,
      }}
    >
      {children}
    </RealTimeContext.Provider>
  );
};
