import React from 'react';
import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { FieldProps, getIn } from 'formik';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export interface DatePickerFieldProps
    extends FieldProps,
        Omit<KeyboardDateTimePickerProps, 'name' | 'value' | 'onChange'> {
    normalize?: (value: MaterialUiPickersDate) => MaterialUiPickersDate;
}

export const DatePickerField: React.FC<DatePickerFieldProps> = ({ field, form, normalize, ...other }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const currentError = getIn(form.touched, field.name) && getIn(form.errors, field.name);

    return (
        <KeyboardDateTimePicker
            autoOk
            ampm={false}
            name={field.name}
            value={field.value}
            format="dd.MM.yyyy HH:mm"
            variant={isMobile ? 'dialog' : 'inline'}
            inputVariant="outlined"
            fullWidth
            helperText={currentError}
            error={Boolean(currentError)}
            // if you are using custom validation schema you probably want to pass `true` as third argument
            onChange={(date) => {
                const value = normalize ? normalize(date) : date?.toISO();
              form.setFieldValue(field.name, value, true);
          }}
            {...other}
        />
    );
   

};
