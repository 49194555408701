import React, { Fragment, useEffect, useState } from 'react';
import { useAuth, getAccessToken } from 'context/AuthContext';
import { api } from 'api/api';
import browserHistory from 'lib/browser-history';

const AuthGuard: React.FC = ({ children }) => {
    const { user, isLoggedIn } = useAuth();
    const [authenticated, setAuthenticated] = useState(true);

    const validateUser = async (): Promise<void> => {
        try {
            const axios = await api();
            const res = await axios.post('/users/validate', { token: getAccessToken(), userName: user?.username });
            if (res.status === 200) {
                setAuthenticated(true);
            } else {
                setAuthenticated(false);
            }
        } catch {
            setAuthenticated(false);
        }
    };

    useEffect(() => {
        if (user) {
            validateUser();
        }
        if (!authenticated || !isLoggedIn) {
            browserHistory.push('/auth/signin');
        }
    }, []);

    useEffect(() => {
        if (!authenticated || !isLoggedIn) {
            browserHistory.push('/auth/signin');
        }
    }, [authenticated]);

    return <Fragment>{children}</Fragment>;

    // return authenticated ? <Fragment>{children}</Fragment> : null;
};

export default AuthGuard;
