import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    modal: {
        backgroundColor: '#fbfbfb',
        overflowY: 'unset',
    },
    deleteModalText: {
        fontSize: '20px',
        textAlign: 'center',
    },
    commonModalButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '30px!important',
        paddingBottom: '20px!important',
        '& button': {
            margin: '0px 10px',
        },
    },

}));
