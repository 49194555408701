import React, { useState, useEffect } from "react";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MenuItem, List, Drawer, IconButton } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { useStyles } from "../sidebar/sidebar.style";
import browserHistory from "lib/browser-history";
import { useDrawer } from "context/DrawerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPollH,
  faCalendarDay,
  faStethoscope,
  faBuilding,
  faCapsules,
  faUsers,
  faTv,
  faFileAlt,
  faTasks,
  faChartPie,
  faThLarge,
  faSlidersH,
} from "@fortawesome/free-solid-svg-icons";
import MenuIcon from "@material-ui/icons/Menu";
import { api } from "api/api";
import { useAuth } from "context/AuthContext";

export const Sidebar: React.FC = () => {
  const { user } = useAuth();

  const ListItems = [
    {
      name: "Əsas səhifə",
      icon: faThLarge,
      url: "",
      permissionName: "",
    },
    {
      name: "Tapşırıqlar",
      icon: faTasks,
      url: "tasks",
      permissionName: "Task_View",
    },
    {
      name: "Vizitlər",
      icon: faCalendarDay,
      url: "visits",
      permissionName: "Visit_View",
    },
    {
      name: "Müştərilər",
      icon: faStethoscope,
      url: "doctors",
      permissionName: "Doctor_View",
    },
    {
      name: "Obyektlər",
      icon: faBuilding,
      url: "objects",
      permissionName: "ParlaObject_View",
    },
    {
      name: "Məhsullar",
      icon: faCapsules,
      url: "pharmas",
      permissionName: "Pharma_View",
    },
    {
      name: "İstifadəçilər",
      icon: faUsers,
      url: "users",
      permissionName: "Employee_View",
    },
    {
      name: "Təqdimatlar",
      icon: faTv,
      url: "presentation/list",
      permissionName: "Presentation_View",
    },
    {
      name: "Sorğular",
      icon: faPollH,
      url: "queries",
      permissionName: "Inquiry_View",
    },
    {
      name: "Analitika",
      icon: faChartPie,
      url: "analitics",
      permissionName: "Analitics_View",
    },
    {
      name: "Hesabatlar",
      icon: faFileAlt,
      url: "reports",
      permissionName: "",
    },
    {
      name: "Tənzimləmələr",
      icon: faSlidersH,
      url: "settings",
      permissionName: "Setting_View",
    },
  ];

  const { open, closeDrawer, openDrawer } = useDrawer();
  const classes = useStyles(open);

  const [menuOpen] = useState(false);

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [logoUrl, setLogoUrl] = useState("");
  const prevOpen = React.useRef(menuOpen);
  const [selected, setSelected] = useState(null);
  const updateSelected = (selectedIndex) => {
    setSelected(selectedIndex);
  };
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [menuOpen]);
  const getLogo = async () => {
    try {
      const axios = await api();
      const res = await axios.get(`/logo/getLogo`);
      setLogoUrl("https://s3.parlapharma.com/parla-ci/" + res?.data?.fileName);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLogo();
  }, []);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
          <div
            className={clsx(classes.toolbarButton, {
              [classes.toolbarButtonClosed]: !open,
            })}
          >
            <div>
              {open ? (
                <IconButton
                  disableRipple
                  style={{ color: "#A2A2A2" }}
                  onClick={closeDrawer}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <IconButton
                  disableRipple
                  style={{ color: "#A2A2A2" }}
                  onClick={openDrawer}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </div>
          </div>
          <div>
            <img
              src={logoUrl}
              className={clsx(classes.imageLogo, {
                [classes.imageLogoClosed]: !open,
              })}
              alt="logo"
            />
          </div>
        </div>

        <List className={classes.list}>
          {ListItems.map((item, index) => (
            <MenuItem
              disabled={
                user?.permissions?.find(
                  (p) => p?.permissionName === item?.permissionName
                ) || item?.permissionName === ""
                  ? false
                  : true
              }
              onClick={(): void => {
                browserHistory.push(`/${item.url}`);
                updateSelected(index);
              }}
              className={clsx(classes.listItems, {
                [classes.listItemsClosed]: !open,
              })}
              button
              key={item.name}
              selected={selected === index}
            >
              <IconButton
                disableRipple
                className={clsx(classes.icon, { [classes.iconClosed]: !open })}
              >
                <FontAwesomeIcon
                  className={clsx(classes.icon, {
                    [classes.iconClosed]: !open,
                  })}
                  icon={item.icon}
                />
              </IconButton>
              <ListItemText
                style={{ display: open ? "unset" : "none" }}
                classes={{ primary: classes.listItemText }}
                primary={item.name}
              />
            </MenuItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};
