import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";

// ---------------------------------Home-----------------------------------------
const HomePage = lazy(() =>
  import("views").then((module) => ({ default: module.Home }))
);
//----------------------------------Users-----------------------------------------
//----------------------------------Personal Profile ------------------------------///
const PersonalProfilePage = lazy(() =>
  import("views").then((module) => ({ default: module.PersonalProfile }))
);
//-------------------------------------Notification Page ------------------------------------------//
const NotificationPage = lazy(() =>
  import("views").then((module) => ({ default: module.NotificationPage }))
);

const UsersPage = lazy(() =>
  import("views").then((module) => ({ default: module.Users }))
);
const SingleUserPage = lazy(() =>
  import("views").then((module) => ({ default: module.SingleUser }))
);

const AddNewUserPage = lazy(() =>
  import("views").then((module) => ({ default: module.AddNewUser }))
);
const EditUserPage = lazy(() =>
  import("views").then((module) => ({ default: module.EditUser }))
);
//-----------------------------------Pharma-------------------------------------------
const PharmasPage = lazy(() =>
  import("views").then((module) => ({ default: module.Pharmas }))
);
const AddNewPharmaPage = lazy(() =>
  import("views").then((module) => ({ default: module.AddNewPharma }))
);
const EditPharmaPage = lazy(() =>
  import("views").then((module) => ({ default: module.EditPharma }))
);
const SinglePharmaPage = lazy(() =>
  import("views").then((module) => ({ default: module.SinglePharma }))
);
//-------------------------------------Queries------------------------------------------
const QueriesPage = lazy(() =>
  import("views").then((module) => ({ default: module.Queries }))
);
const AddNewQueryPage = lazy(() =>
  import("views").then((module) => ({ default: module.AddNewQuery }))
);
const EditQueryPage = lazy(() =>
  import("views").then((module) => ({ default: module.EditQuery }))
);
const QueryDetailPage = lazy(() =>
  import("views").then((module) => ({ default: module.QueryDetail }))
);
//---------------------------------------Analitics-----------------------------------------
const AnaliticsPage = lazy(() =>
  import("views").then((module) => ({ default: module.Analitics }))
);
const TargetsPage = lazy(() =>
  import("views").then((module) => ({ default: module.Targets }))
);
const WorkersPage = lazy(() =>
  import("views").then((module) => ({ default: module.Workers }))
);
const SalesPage = lazy(() =>
  import("views").then((module) => ({ default: module.Sales }))
);
const SalesAddNewPage = lazy(() =>
  import("views").then((module) => ({ default: module.SalesAddNew }))
);
const SalesEditPage = lazy(() =>
  import("views").then((module) => ({ default: module.SalesEdit }))
);
const SingleSalesPage = lazy(() =>
  import("views").then((module) => ({ default: module.SingleSale }))
);
const EditWorkersTable = lazy(() =>
  import("views").then((module) => ({ default: module.EditWorkersTable }))
);
//---------------------------------------objects--------------------------------------------
const ObjectsPage = lazy(() =>
  import("views").then((module) => ({ default: module.Objects }))
);
const AddNewObjectPage = lazy(() =>
  import("views").then((module) => ({ default: module.AddNewObject }))
);
const EditObjectPage = lazy(() =>
  import("views").then((module) => ({ default: module.EditObject }))
);
const SingleObjectPage = lazy(() =>
  import("views").then((module) => ({ default: module.SingleObject }))
);
//---------------------------------------doctors----------------------------------------------
const DoctorsPage = lazy(() =>
  import("views").then((module) => ({ default: module.Doctors }))
);
const AddNewDoctorPage = lazy(() =>
  import("views").then((module) => ({ default: module.AddNewDoctor }))
);
const EditDoctorPage = lazy(() =>
  import("views").then((module) => ({ default: module.EditDoctor }))
);
const SingleDoctorPage = lazy(() =>
  import("views").then((module) => ({ default: module.SingleDoctor }))
);
//--------------------------------------visits---------------------------------------------------
const VisitsPage = lazy(() =>
  import("views").then((module) => ({ default: module.Visits }))
);
const AddNewVisitPage = lazy(() =>
  import("views").then((module) => ({ default: module.AddVisit }))
);
const EditVisitPage = lazy(() =>
  import("views").then((module) => ({ default: module.EditVisit }))
);
const SingleVisitPage = lazy(() =>
  import("views").then((module) => ({ default: module.SingleVisit }))
);
const InQueryPage = lazy(() =>
  import("views").then((module) => ({ default: module.InQuery }))
);
const CopyVisitPage = lazy(() =>
  import("views").then((module) => ({ default: module.CopyVisit }))
);

//---------------------------------------taskPage--------------------------------------------------
const TasksPage = lazy(() =>
  import("views").then((module) => ({ default: module.Tasks }))
);
const AddNewTaskPage = lazy(() =>
  import("views").then((module) => ({ default: module.AddNewTask }))
);
const EditTaskPage = lazy(() =>
  import("views").then((module) => ({ default: module.EditTask }))
);
const SingleTaskPage = lazy(() =>
  import("views").then((module) => ({ default: module.SingleTask }))
);
const CopyTaskPage = lazy(() =>
  import("views").then((module) => ({ default: module.CopyTask }))
);
//------------------------------------Presentation---------------------------------------------------
const PresentationsPage = lazy(() =>
  import("views").then((module) => ({ default: module.Presentations }))
);
const PresentationCreatePage = lazy(() =>
  import("views").then((module) => ({ default: module.PresentationCreate }))
);
const PresentationEditPage = lazy(() =>
  import("views").then((module) => ({ default: module.PresentationEdit }))
);
const PresentationDetailPage = lazy(() =>
  import("views").then((module) => ({ default: module.PresentationDetail }))
);
//----------------------------------------------settings------------------------------------------
const SettingsPage = lazy(() =>
  import("views").then((module) => ({ default: module.Settings }))
);
//-----------------------------------------------reports------------------------------
const ReportsPage = lazy(() =>
  import("views").then((module) => ({ default: module.ReportsPage }))
);
const SalesReportPage = lazy(() =>
  import("views").then((module) => ({ default: module.SalesReportPage }))
);
const VisitsReportPage = lazy(() =>
  import("views").then((module) => ({ default: module.VisitsReportPage }))
);
const PresentationReportPage = lazy(() =>
  import("views").then((module) => ({ default: module.PresentationReportPage }))
);
const PresentationReportSinglePage = lazy(() =>
  import("views").then((module) => ({
    default: module.SinglePresentationReport,
  }))
);
// const PresentationPreviewReportPage = lazy(() =>
//   import("views").then((module) => ({
//     default: module.PresentationPreviewReportPage,
//   }))
// );
const CustomerStatusReportPage = lazy(() =>
  import("views").then((module) => ({
    default: module.CustomerStatusReportPage,
  }))
);
const StockReportPage = lazy(() =>
  import("views").then((module) => ({ default: module.StockReportPage }))
);
const CreateStock = lazy(() =>
  import("views").then((module) => ({ default: module.CreateStock }))
);

const OpponentReportPage = lazy(() =>
  import("views").then((module) => ({ default: module.OpponentReportPage }))
);
const AddOpponent = lazy(() =>
  import("views").then((module) => ({ default: module.AddOpponent }))
);
const SingleOpponent = lazy(() =>
  import("views").then((module) => ({ default: module.SingleOpponent }))
);

const AppRouter: React.FC = () => (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/userPersonalProfile" component={PersonalProfilePage} />
    <Route exact path="/notifications" component={NotificationPage} />
    <Route exact path="/users" component={UsersPage} />
    <Route exact path="/users/add" component={AddNewUserPage} />
    <Route exact path="/users/:id" component={SingleUserPage} />
    <Route exact path="/users/edit/:id" component={EditUserPage} />
    <Route exact path="/pharmas" component={PharmasPage} />
    <Route exact path="/pharmas/add" component={AddNewPharmaPage} />
    <Route exact path="/pharmas/edit/:id" component={EditPharmaPage} />
    <Route exact path="/pharmas/:id" component={SinglePharmaPage} />
    <Route exact path="/queries" component={QueriesPage} />
    <Route exact path="/queries/add" component={AddNewQueryPage} />
    <Route exact path="/queries/edit/:id" component={EditQueryPage} />
    <Route exact path="/queries/:id" component={QueryDetailPage} />
    <Route exact path="/analitics" component={AnaliticsPage} />
    <Route exact path="/analitics/targets" component={TargetsPage} />
    <Route exact path="/analitics/workers" component={WorkersPage} />
    <Route exact path="/analitics/sales" component={SalesPage} />
    <Route exact path="/analitics/sales/add" component={SalesAddNewPage} />
    <Route exact path="/analitics/sales/edit/:id" component={SalesEditPage} />
    <Route exact path="/analitics/sales/:id" component={SingleSalesPage} />
    <Route
      exact
      path="/analitics/workers/edit/:id"
      component={EditWorkersTable}
    />
    <Route exact path="/objects" component={ObjectsPage} />
    <Route exact path="/objects/add" component={AddNewObjectPage} />
    <Route exact path="/objects/edit/:id" component={EditObjectPage} />
    <Route exact path="/objects/:id" component={SingleObjectPage} />
    <Route exact path="/doctors" component={DoctorsPage} />
    <Route exact path="/doctors/add" component={AddNewDoctorPage} />
    <Route exact path="/doctors/edit/:id" component={EditDoctorPage} />
    <Route exact path="/doctors/:id" component={SingleDoctorPage} />
    <Route exact path="/visits" component={VisitsPage} />
    <Route exact path="/visits/add" component={AddNewVisitPage} />
    <Route exact path="/visits/edit/:id" component={EditVisitPage} />
    <Route exact path="/visits/:id" component={SingleVisitPage} />
    <Route
      exact
      path="/visits/:id/completeQuery/:qId"
      component={InQueryPage}
    />
    <Route exact path="/visits/copy/:id" component={CopyVisitPage} />
    <Route exact path="/tasks" component={TasksPage} />
    <Route exact path="/tasks/add" component={AddNewTaskPage} />
    <Route exact path="/tasks/edit/:id" component={EditTaskPage} />
    <Route exact path="/tasks/:id" component={SingleTaskPage} />
    <Route exact path="/tasks/copy/:id" component={CopyTaskPage} />

    <Route exact path="/presentation/list" component={PresentationsPage} />
    <Route exact path="/presentation/add" component={PresentationCreatePage} />
    <Route
      exact
      path="/presentation/:id/edit"
      component={PresentationEditPage}
    />
    <Route exact path="/presentation/:id" component={PresentationDetailPage} />
    <Route exact path="/settings" component={SettingsPage} />
    <Route exact path="/reports" component={ReportsPage} />
    <Route exact path="/reports/sales" component={SalesReportPage} />
    <Route exact path="/reports/visits" component={VisitsReportPage} />
    <Route
      exact
      path="/reports/presentation"
      component={PresentationReportPage}
    />
    {/* <Route
      exact
      path="/reports/presentation/:id"
      component={PresentationPreviewReportPage}
    /> */}
    <Route
      exact
      path="/reports/presentation/:id"
      component={PresentationReportSinglePage}
    />
    <Route
      exact
      path="/reports/customerStatus"
      component={CustomerStatusReportPage}
    />
    <Route exact path="/reports/stocks" component={StockReportPage} />
    <Route exact path="/reports/stocks/create" component={CreateStock} />
    <Route exact path="/reports/opponent" component={OpponentReportPage} />
    <Route exact path="/reports/opponent/add" component={AddOpponent} />
    <Route exact path="/reports/opponent/:id" component={SingleOpponent} />
  </Switch>
);
export default AppRouter;
