import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

const SignInPage = lazy(() => import('views').then((module) => ({ default: module.SignIn })));
const ForgotPasswordPage = lazy(() => import('views').then((module) => ({ default: module.ForgotPassword })));
const ResetPasswordPage = lazy(() => import('views').then((module) => ({ default: module.ResetPassword })));

const AuthRouter = () => (
    <Switch>
        <Route exact path="/auth/signin" component={SignInPage} />
        <Route exact path="/auth/forgot" component={ForgotPasswordPage} />
        <Route exact path="/auth/reset" component={ResetPasswordPage} />
    </Switch>
);
export default AuthRouter;
