import React from 'react';
import Dialog from '@material-ui/core/Dialog';

interface Props {
    open: boolean;
    handleClose: () => void;
    children: any;
    className?: any | undefined;
}

export const RModal: React.FC<Props> = ({ open, handleClose, children, className }) => {
    return (
        <div>
            <Dialog
                onClose={handleClose}
                classes={{
                    paper: className,
                }}
                open={open}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {children}
            </Dialog>
        </div>
    );
};
